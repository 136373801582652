import { useMemo, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";

import Layout from './pages/Layout';
import HomePage from './pages/Home';
import AboutPage from './pages/About';
import DesignConfigs from "./PageConfigs/DesignConfigs";
import SoftwareConfigs from './PageConfigs/SoftwareConfigs';
import SubPageTemplate from './pages/SubPageTemplate';
import './App.css';

import Background from "./components/background";

function App() {
  useEffect(() => {
    document.title = 'Joshua Nguyen';  
  }, []);

  const getSoftwareSubpageRoutes = useMemo(() => {
    return SoftwareConfigs.map((config) => {
      return (<Route key={config.id} path={`/software/${config.id}`} element={<SubPageTemplate config={config} section={'Software'} />} />)
    });
  }, []);

  const getDesignSubpageRoutes = useMemo(() => {
    return DesignConfigs.map((config) => {
      return (<Route key={config.id} path={`/design/${config.id}`} element={<SubPageTemplate config={config} section={'Design'} />} />)
    });
  }, []);

  return (
    <div className="App">
      <div className='z-[100] absolute top-0'>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<HomePage />} />
                <Route path="/software" element={<Navigate to='/software/joshncav1' />} />
                {getSoftwareSubpageRoutes}
                <Route path="/design" element={<Navigate  to='/design/logos' />} />
                {getDesignSubpageRoutes}
                <Route path="/about" element={<AboutPage />} />
              </Route>
            </Routes>
        </BrowserRouter>
        <Outlet />
      </div>
      <Background />
    </div>
  );
}

export default App;
