import homepage from '../../images/joshncav1/homepage.png';
import homepage2_runPage_design from '../../images/joshncav1/homepage2_runPage_design.png';
import homepage2_stack from '../../images/joshncav1/homepage2_stack.png';
import homepage2_submenu_scroll from '../../images/joshncav1/homepage2_submenu_scroll.png';

const joshnca = {
    id: 'joshncav1',
    title: 'Joshn.ca V1',
    description: 'The problem I faced with past iterations of my website was an inconsistent design and structure. I had 3 separate Wordpress websites: a development portfolio, design portfolio, and running blog, all threaded together with links from a central HTML page. It was difficult to manage and also did not provide a good user experience; visitors would have to navigate away to my other pages to see different content. This iteration of my website unifies all of my work, sharing one design, one framework, and one back end. In trying to learn and implement some new frameworks, I had to move away from Wordpress. Thus, I needed a way to migrate my old posts to this new site. The solution was to make my own content managment system to add and retrieve data. The data is then wrapped around its respective HTML tags, injected into ReactJS components, and rendered to the DOM.',
    skills: [
        'React',
        'Heroku',
        'HHTML 5',
        'CSS3',
        'Bootstrap Components',
        'jQuery',
        'NodeJs',
        'Express',
        'SQLite'
    ],
    imagePaths: [
        homepage,
        homepage2_submenu_scroll,
        homepage2_stack,
        homepage2_runPage_design,
    ]
};

export default joshnca;