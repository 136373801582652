import flightBoard1 from '../../images/flightboard/imgFB1.jpg';
import flightBoard3 from '../../images/flightboard/imgFB3.jpg';
import flightBoard4 from '../../images/flightboard/imgFB4.jpg';
import flightBoard5 from '../../images/flightboard/imgFB5.jpg';
import flightBoard6 from '../../images/flightboard/imgFB6.jpg';
import flightBoard8 from '../../images/flightboard/imgFB8.jpg';
import flightBoard9 from '../../images/flightboard/imgFB9.jpg';
import flightBoard10 from '../../images/flightboard/imgFB10.jpg';
import flightBoard16 from '../../images/flightboard/imgFB16.jpg';
import flightBoard17 from '../../images/flightboard/imgFB17.jpg';

export default {
    id: 'flightboard',
    title: 'Flightboard',
    description: 'FlightBoard is a complete and robust scheduling tool to help flight planners and coordinators manage personnel data, aircraft data, and flight data.',
    skills: [
        'Logo design',
        'Print media',
        'Website design and implementation',
        'Photo-realistic web graphics',
        'Photography',
        'Photo editing'
    ],
    imagePaths: [
        flightBoard1,
        flightBoard3,
        flightBoard4,
        flightBoard5,
        flightBoard6,
        flightBoard8,
        flightBoard9,
        flightBoard10,
        flightBoard16,
        flightBoard17
    ]
};