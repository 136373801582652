import flightboard from "./flightboard";
import solium from "./solium";
import logos from './logos';
import inLoveAndWar from "./inLoveAndWar";
import jaisel from "./jaisel";
import selfies from "./selfies";
import sketches from "./sketches";
import vSandwiches from "./vSandwiches";


export default [
    logos,
    flightboard,
    solium,
    inLoveAndWar,
    jaisel,
    selfies,
    sketches,
    vSandwiches
];
