import dr2dr1 from '../../images/dr2dr/dr2dr_onboarding_animation.mp4';
import dr2dr2 from '../../images/dr2dr/contact-directory-768x483.png';
import dr2dr3 from '../../images/dr2dr/dr2dr3.png';

const dr2dr = {
    id: 'dr2dr',
    title: 'dr2dr',
    description: 'dr2dr is a secure messaging platform created by Microquest Inc. It is also the project I contributed to during my internship with the company over the past 1.6 years. This experience has taught me many things about the software development industry and has allowed me to mature as a developer. I’ve been able to confidently take requirements and build out new features, full-stack. I was able to pick up Javascript, and the NodeJS and AngularJS frameworks. In striving to build a secure and robust system, I spent much time was writing unit tests for the new features I was creating. I was also able to put my graphic design skill so good use and designed the logo!',
    skills: ['Angular, HTML, CSS, NPM, Node', "Teamwork"],
    imagePaths: [
        dr2dr1,
        dr2dr2,
        dr2dr3
    ]
};

export default dr2dr;