import imgLovewar1 from '../../images/inLoveAndWar/imglovewar1.jpg';
import imgLovewar2 from '../../images/inLoveAndWar/imglovewar2.jpg';
import imgLovewar3 from '../../images/inLoveAndWar/imglovewar3.jpg';
import imgLovewar4 from '../../images/inLoveAndWar/imglovewar4.jpg';

const inLoveAndWar = {
    id: 'inLoveAndWar',
    title: 'In Love & War',
    description: 'In Love & War was the personal blog of Carrie Hoang. She writes about life, fashion, relationships, music, food, and many other inspiring things in her life.',
    skills: [
        'Logo design',
        'Website design',
        'Wordpress'
    ],
    imagePaths: [
        imgLovewar1,
        imgLovewar2,
        imgLovewar3,
        imgLovewar4
    ]
};

export default inLoveAndWar;