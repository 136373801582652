const NavMenuItem = (props) => {
    const { active, children } = props;

    return (
        <li className={`my-3 text-4xl transition ease-in-out duration-300 cursor-pointer opacity-80 hover:blur-[1px] ${active ? 'font-bold blur-[1px] opacity-100' : 'blur-[2px] '}`}>
            {children}
        </li>
    )
}

export default NavMenuItem;
