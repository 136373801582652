import pontoonVideo from '../../images/mozillaPontoon/ucosp_mqm_interface.mp4';

const pontoon = {
    id: 'mozillaPontoon',
    title: 'Mozilla Pontoon',
    description: 'As part of a course I am taking this semester, a small group of students from across the country are grouped up and are tasked with creating an interface for a new feature. We collaborated and brainstormed countless solutions for an awesome new tool that allows users to provide feedback and categorical issue tags to other user submissions.',
    skills: ['HTML', 'CSS','jQuery'],
    imagePaths: [pontoonVideo]
};

export default pontoon;