import imgSolium_holiday from '../../images/solium/imgSolium_holiday.jpg';
import imgSolium_map from '../../images/solium/imgSolium_map.jpg';
import imgSolium_soccer from '../../images/solium/imgSolium_soccer.jpg';
import imgSolium_stock from '../../images/solium/imgSolium_stock.jpg';
import imgSolium_stock2 from '../../images/solium/imgSolium_stock2.jpg';
import imgSolium_typo from '../../images/solium/imgSolium_typo.jpg';
import imgSolium_website1 from '../../images/solium/imgSolium_website1.jpg';
import imgSolium_website2 from '../../images/solium/imgSolium_website2.jpg';

const solium = {
    id: 'solium',
    title: 'Solium Capital',
    description: 'Solium (now Morgan Stanley) is a Calgary based software company whose flagship products allow companies to administer their shares to their employees.',
    skills: [
        'Logo design',
        'Print media',
        'Website design',
        'Photo editing'
    ],
    imagePaths: [
        imgSolium_website1,
        imgSolium_website2,
        imgSolium_holiday,
        imgSolium_map,
        imgSolium_soccer,
        imgSolium_stock,
        imgSolium_stock2,
        imgSolium_typo,
    ]
};

export default solium;