import popout1 from '../../images/youtubePopout/popout1.png';
import popout2 from '../../images/youtubePopout/popout2.png';
import popout3 from '../../images/youtubePopout/popout3.png';

const youtubePopout = {
    id: 'youtubePopout',
    title: 'Youtube Popout',
    description: 'YouTube Popout is a solution i developed in order to help me multi-task. It was also an opportunity for me to try my hand at JavaScript and Chrome extensions, for the first time. YouTube Popout checks if your current browser tab is at a YouTube video, then pops the video out into a Google Panel – neatly positioned at the bottom of your computer screen. It stays above all of your other windows so you can continue enjoying cat videos while doing important work.',
    skills: ['JavaScript', 'Adobe Illustrator'],
    imagePaths: [
        popout2,
        popout1,
        popout3
    ]
};

export default youtubePopout;