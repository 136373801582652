import PageContainer from '../components/PageContainer';
import ProfilePicture from '../images/joshuaNguyenProfile.jpg';

const AboutPage = () => {
    return (
        <PageContainer>
            <div className="mt-[80px] mb-[80px] lg:mt-[130px] max-w-[800px] text-left">
              <div className=' text-4xl lg:text-6xl mb-[40px] blur-[1px]'>About</div>
              <div>
                <img src={ProfilePicture}  />
              </div>
              <div>
                <p className="my-4">
                    My name, as you would have guessed, is Joshua Nguyen. I'm a full-stack software engineer based out of Edmonton, Alberta, Canada and have been in the industry for over 5 years. 
                </p>
                <p className="my-4">
                    I have a Bachelor's Degree in Computer Science from the University of Alberta as well as a Graphic Design certification from Pixel Blue College.
                </p>
                <p className="my-4">
                    I am currently a software engineer at an exciting new start-up in the health-tech space. We are aiming to disrupt the traditional medical space in the states, which is plagued by preditorial big pharma companies. It is an exciting journey as we try to build out solutions to bring information and healing to everyone.
                </p>
                <p className="my-4">
                    Prior, I worked at Intuit Canada for over 4 years - working on core Quickbooks Online features to streamline day-to-day tasks of the small business owner. To support all of Intuit products, I also worked on internal tooling for an AI based assistant that removed brought answers to users quicker while simultaneously removing overhead for the company.
                </p>
                <p className="my-4">Outside of work, I enjoy building personal projects. Currently, I am working on a family tree service that brings together loved ones especially in times of great disconnect. I am also big into cycling and running! Hopefully I can finish another marathon next season!</p>
              </div>
              <div><a href="https://github.com/jivedesign" target="blank" className='font-bold hover:underline'>Github</a></div>
              <div><a href="https://www.linkedin.com/in/josh-nguyen-59270a169/" target="blank" className='font-bold hover:underline'>LinkedIn</a></div>
            </div>
        </PageContainer>
        
    )

}

export default AboutPage;