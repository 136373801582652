const SubpageContainer = (props) => {
    const { children } = props;

    return (
        <div className="relative p-6 lg:p-0">
            {children}
        </div>
    )
}

export default SubpageContainer;