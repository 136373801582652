import studyWell2 from '../../images/studywell/studyWell2.png';
import studyWell3 from '../../images/studywell/studyWell3.png';
import studyWell4 from '../../images/studywell/studyWell4.png';
import studyWell5 from '../../images/studywell/studyWell5.png';
import studyWell6 from '../../images/studywell/studyWell6.png';

const studyWell = {
    id: 'studyWell',
    title: 'Study Well',
    description: 'As a student, it is tedious to recalculate my GPA and determine how well I was doing in the class whenever I received my marks for an exam or an assignment. StudyWell is a solution for intuitively tracking your progress through courses and semesters. The GPA is calculated based on the GPA formula used by the University of Alberta.',
    skills: ['Java', "Android Studio"],
    imagePaths: [
        studyWell6,
        studyWell2,
        studyWell3,
        studyWell4,
        studyWell5,
    ]
};

export default studyWell;