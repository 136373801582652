const PageContainer = (props) => {
    const { children } = props;

    return (
        <div className="p-6 lg:p-0">
            {children}
        </div>
    )
}

export default PageContainer;