import curiousCat from "./curiousCat";
import dr2dr from "./dr2dr";
import joshncav1 from "./joshncav1";
import mozillaPontoon from "./mozillaPontoon";
import studyWell from "./studyWell";
import youtubePopout from "./youtubePopout";

export default [
    joshncav1,
    dr2dr,
    curiousCat,
    mozillaPontoon,
    studyWell,
    youtubePopout
];