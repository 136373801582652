import PageContainer from '../components/PageContainer';

const HomePage = () => {
    return (
        <PageContainer>
            <div className="flex">
            </div>
        </PageContainer>
        
    )

}

export default HomePage;