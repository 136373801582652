import imgVsandMenu1 from '../../images/vSandwiches/imgVsand-menu1.jpg';
import imgVsandMenu2 from '../../images/vSandwiches/imgVsand-menu2.jpg';
import imgVsandSite1 from '../../images/vSandwiches/imgVsand-site1.jpg';
import imgVsandSite2 from '../../images/vSandwiches/imgVsand-site2.jpg';
import imgVsand1 from '../../images/vSandwiches/imgVsand1.jpg';
import imgVsand2 from '../../images/vSandwiches/imgVsand2.jpg';

const vSandwiches = {
    id: 'vSandwiches',
    title: 'V Sandwiches',
    description: 'V Sandwiches is a vietnamese restaurant situated on Edmonton’s trendy Whyte Avenue. They serve Vietnamese Banh Mi (Sandwiches) with a modern twist.',
    skills: [
        'Logo design',
        'Print media',
        'Website design and implementation',
        'Photography',
        'Photo editing'
    ],
    imagePaths: [
        imgVsand1,
        imgVsand2,
        imgVsandMenu1,
        imgVsandMenu2,
        imgVsandSite1,
        imgVsandSite2,
    ]
};

export default vSandwiches;