import lotr from '../../images/selfies/26575_lord_of_the_rings_the_fellowship_of_the_ring_ver_xlg.jpg';
import avatar from '../../images/selfies/avatar-660x1024.jpg';
import spider from '../../images/selfies/Screen-Shot-2014-05-23-at-4.45.18-PM.png';
import snowboard from '../../images/selfies/snowboardJump-1024x830.png';
import startrek from '../../images/selfies/startrek-691x1024.png';

const selfies = {
    id: 'selfies',
    title: 'Selfies',
    description: 'Lots of fun stuff, here. A collection of self-initiated photo-manipulation projects. The exercise is to utilize available tools and skills to create interesting and convincing images.',
    skills: ['Photography', "Adobe Photoshop"],
    imagePaths: [
        lotr,
        avatar,
        spider,
        snowboard,
        startrek,
    ]
};

export default selfies;