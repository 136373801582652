import { useEffect, useMemo, useState, useCallback } from 'react';
import { Outlet, Link, useLocation } from "react-router-dom";
import NavContainer from '../components/NavContainer';
import NavMenuItem from '../components/NavMenuItem';
import SubNavMenuItem from '../components/SubNavMenuItem';
import DesignConfigs from '../PageConfigs/DesignConfigs';
import SoftwareConfigs from '../PageConfigs/SoftwareConfigs';

const Layout = () => {
    const [rootPath, setRootPath] = useState('');
    const [subPath, setSubPath] = useState('');
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const location = useLocation();

    const getRoutePathname = (route) => {
        const pathElements = route.split('/');
        // const newRouteElements = pathElements.shift();

        return pathElements;
    }

    useEffect(() => {
        const path = getRoutePathname(location.pathname);

        if (path.length > 2) {
            setRootPath(path[1]);
            setSubPath(path[2]);
        } else {
            setRootPath(path[1]);
            setSubPath('');
        }
    }, [location]);

    const isActivePath = useCallback((path) => {
        return rootPath === path
    }, [rootPath]);

    const isActiveSubPath = useCallback((path) => {
        return subPath === path;

    }, [location, subPath]);

    const handleToggleMobileMenuOpen = useCallback(() => {
        setMobileMenuOpen(!mobileMenuOpen);
    }, [mobileMenuOpen]);

    const handleSelectMenuItem = useCallback(() => {
        setMobileMenuOpen(false);
    }, [mobileMenuOpen]);

    const getSubNavigationMenuItems = useMemo(() => {
        switch(rootPath) {
            case 'software': 
                return SoftwareConfigs.map((config) => {
                    return (
                        <SubNavMenuItem key={config.id} active={isActiveSubPath(config.id)}>
                            <Link to={`/software/${config.id}`} onClick={handleSelectMenuItem}>{config.title}</Link>
                        </SubNavMenuItem>
                    )
                });
            case 'design':
                return DesignConfigs.map((config) => {
                    return (
                        <SubNavMenuItem key={config.id} active={isActiveSubPath(config.id)}>
                            <Link to={`/design/${config.id}`} onClick={handleSelectMenuItem}>{config.title}</Link>
                        </SubNavMenuItem>
                    )
                });
            case 'about':
            default: 
                return null;
        }
    }, [rootPath, subPath]);

    const mobileMenu = useMemo(() => {
        return (
            <div className='block lg:hidden'>
                <div className={`fixed top-0 p-6 z-[200] text-left w-[100vw] bg-stone-200 ${mobileMenuOpen ? 'h-[100vh]' : 'h-[50px]'}`}>
                    <nav>
                        <div className='flex justify-between bg-stone-200'>
                            <Link to="/" className='font-bold uppercase text-sm' onClick={handleSelectMenuItem}>Joshua Nguyen</Link>
                            <div className="text-sm" onClick={handleToggleMobileMenuOpen}>{mobileMenuOpen ? 'CLOSE' : 'MENU'}</div>
                        </div>
                        <div className={`${mobileMenuOpen ? 'block' : 'hidden'}`}>
                            <ul className='mt-8'>
                                <NavMenuItem active={isActivePath('software')}>
                                    <Link to="software" onClick={handleSelectMenuItem}>Software</Link>
                                </NavMenuItem>
                                <NavMenuItem active={isActivePath('design')}>
                                    <Link to="design" onClick={handleSelectMenuItem}>Design</Link>
                                </NavMenuItem>
                                <NavMenuItem active={isActivePath('about')}>
                                    <Link to="about" onClick={handleSelectMenuItem}>About</Link>
                                </NavMenuItem>
                            </ul>

                            <ul className='mt-8'>
                                {getSubNavigationMenuItems}
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        )
    }, [mobileMenuOpen, getSubNavigationMenuItems])

  return (
    <div className="flex flex-col lg:flex-row">
        <div className="">
            {mobileMenu}
            
            <div className={`hidden lg:block sticky top-0`}>
                <div className="p-20 z-[200] z-0 text-left w-[420px] ">
                    <nav>
                        <div>
                            <Link to="/" className='font-bold uppercase text-sm'>Joshua Nguyen</Link>
                        </div>
                        <ul className='mt-8'>
                            <NavMenuItem active={isActivePath('software')}>
                                <Link to="software">Software</Link>
                            </NavMenuItem>
                            <NavMenuItem active={isActivePath('design')}>
                                <Link to="design">Design</Link>
                            </NavMenuItem>
                            <NavMenuItem active={isActivePath('about')}>
                                <Link to="about">About</Link>
                            </NavMenuItem>
                        </ul>

                        <ul className='mt-8'>
                            {getSubNavigationMenuItems}
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
      
      {/* The resulting page to be rendered */}
      <div className="flex-1">
        <Outlet />

      </div>
    </div>
  )
};

export default Layout;
