import SubpageContainer from '../components/SubpageContainer';

const PageTemplate = (props) => {
    const { title, description, skills, imagePaths } = props.config;
    const { section } = props;

    const getSkills = () => {
        return skills.join(', ')
    };

    const renderImages = () => {
        if (imagePaths.length === 0) {
            return null;
        }

        return imagePaths.map((path, index) => {
            return (
                <div className='max-w-[800px]'>
                    <img src={path} key={index} className='mb-5'/>
                </div>
            );
        })
    }

    return (
        <SubpageContainer>
            <div className='sticky top-[40px] lg:top-0 pt-[20px] lg:pt-[130px] bg-stone-200 text-left'>
                <span className=''>{section}</span>
                <div className=" text-4xl lg:text-6xl mt-2 lg:mt-0 pb-5 lg:pb-8 blur-[1px] font-bold">
                    {title}
                </div>
            </div>
            <div className=' max-w-[800px]'>
                <div className='text-left text-sm font-semibold mt-4'>
                    {getSkills()}
                </div>
                <div className="text-left py-8">
                    {description}
                </div>
                <div className='mb-10'>
                    {renderImages()}
                </div>
            </div>
            
        </SubpageContainer>
    );
}

export default PageTemplate;