import imgJaisel1 from '../../images/jaisel/imgJaisel1.jpg';
import imgJaisel2 from '../../images/jaisel/imgJaisel2.jpg';
import imgJaisel3 from '../../images/jaisel/imgJaisel3.jpg';
import imgJaisel4 from '../../images/jaisel/imgJaisel4.jpg';
import imgJaisel5 from '../../images/jaisel/imgJaisel5.jpg';

const jaisel = {
    id: 'jaisel',
    title: 'Jaisel',
    description: 'Jaisel was founded in 2004 and is located a few steps off Whyte Avenue in the Old Strathcona historic district in Edmonton, Canada. Jaisel is an exclusive menswear boutique that focuses on modern classic attire, casual and formal footwear, accessories and raw denim.',
    skills: [
        'Logo design',
        'Print media',
        'Website design and implementation',
        'Photography',
        'Photo editing'
    ],
    imagePaths: [
        imgJaisel1,
        imgJaisel2,
        imgJaisel3,
        imgJaisel4,
        imgJaisel5
    ]
};

export default jaisel;