import CuriousCatLogo from '../../images/logos/curiousCatLogo.png';
import SoftResetLogo from  '../../images/logos/logos_0000_Softreset-1024x768.png';
import GoRobosLogo from  '../../images/logos/logos_0001_GoRobos-1024x768.jpg';
import ElecEngLogo from  '../../images/logos/logos_0003_ElecEng-1024x768.jpg';
import Resp2015Logo from  '../../images/logos/logos_0004_Resp2015-1024x768.jpg';
import AstroKoiLogo from  '../../images/logos/logos_0006_AstroKoi-1024x768.jpg';
import EvacLogo from  '../../images/logos/logos_0008_evac2014-1024x768.jpg';
import ExquisiteLogo from  '../../images/logos/logos_0009_exquisite-1024x768.jpg';
import BrainsLogo from  '../../images/logos/logos_0010_brains-1024x768.jpg';
import GravityLogo from  '../../images/logos/logos_0011_gravity_logo-1024x768.jpg';

export default {
    id: 'logos',
    title: 'Logos',
    description: 'A collection of logos I had the pleasure of creating throughout my career.',
    skills: ['Adobe Illustrator'],
    imagePaths: [
        CuriousCatLogo,
        SoftResetLogo,
        GoRobosLogo,
        ElecEngLogo,
        Resp2015Logo,
        AstroKoiLogo,
        EvacLogo,
        ExquisiteLogo,
        BrainsLogo,
        GravityLogo
    ]
};