import lennon from  '../../images/sketches/char_lennon_final.png';
import joshLe from  '../../images/sketches/imgJoshLe_poster1.jpg';
import louis from  '../../images/sketches/louisLong-FINAL-669x1024.jpg';
import naomi_josh from  '../../images/sketches/naomi_josh_FINAL-01-e1400880875427-789x1024.jpg';

const sketches = {
    id: 'Sketches',
    title: 'Sketches',
    description: 'A colletion of sketches and caricatures of friends, clients, and famous people. The goal is to capture their essence and personality in as few lines as possible.',
    skills: ['Adobe Illustrator'],
    imagePaths: [
        lennon,
        joshLe,
        louis,
        naomi_josh,
    ]
};

export default sketches;