import curiousCatLogo from '../../images/curiousCat/curiousCatLogo.png';
import curiousCat_1 from '../../images/curiousCat/curiousCat_1.png';
import curiousCat_2 from '../../images/curiousCat/curiousCat_2.png';
import curiousCat_3 from '../../images/curiousCat/curiousCat_3.png';
import curiousCat_4 from '../../images/curiousCat/curiousCat_4.png';
import curiousCat_5 from '../../images/curiousCat/curiousCat_5.png';

const curiousCat = {
    id: 'curiousCat',
    title: 'Curious Cat',
    description: 'Curious Cat was our solution to the final project for CMPUT 301: Introduction to Software Engineering, which won Manager’s Pick for best app! Woohoo! The course is centered around Object-oriented design and analysis, with interactive applications as the primary example. Topics include: software process; revision control; Unified Modeling Language (UML); requirements; software architecture, design patterns, frameworks, design guidelines; unit testing; refactoring; software tools. The project is meant for students to demonstrate their knowledge of the taught methodologies in a creative and interactive manner.',
    skills: ['Java', "Android Studio", "Teamwork"],
    imagePaths: [
        curiousCatLogo,
        curiousCat_1,
        curiousCat_2,
        curiousCat_3,
        curiousCat_4,
        curiousCat_5
    ]
};

export default curiousCat;