const SubNavMenuItem = (props) => {
    const { active, children } = props;

    return (
        <li className={`my-3 text-xl transition ease-in-out duration-300 opacity-80 hover:opacity-100 cursor-pointer ${active ? 'font-bold blur-none': 'blur-[1px]'}`}>
            {children}
        </li>
    )
}

export default SubNavMenuItem;
